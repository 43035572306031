import React, { useState, useEffect, useRef, useCallback } from 'react';
import { renderToString, renderToStaticMarkup } from 'react-dom/server'
import './App.scss';
import { Box, Grommet, Select, Layer, Button, TextArea, Text, Image, TextInput } from 'grommet';
import * as Icon from 'grommet-icons'
import FileBase64 from 'react-file-base64';
import TextareaAutosize from 'react-textarea-autosize';
import { useDropzone } from 'react-dropzone'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const textToSend = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
const imgPlaceholder = "https://nutrisport.es/web/wp-content/uploads/2019/12/placeholder.png"

const ImageElement = ({ section, dropCallback, edit }) => {

  const onDrop = useCallback(async acceptedFiles => {
    // Do something with the files
    const file = acceptedFiles[0];
    dropCallback({
      uri: await toBase64(file),
      filename: file.name.replace(/[^.A-Z0-9]+/ig, "_")
    })
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
  // console.log(section)
  return (
    <div
      {...(edit ? { ...getRootProps() } : {})}
      // onClick={setImage}
      // image={section && section.image && section.image.uri}
      type="bImage"
      className="bImage"
      style={{ border: edit ? "4px solid gainsboro" : "0px" }}
    >
      {isDragActive ?
        <div style={{ display: "flex", width: "100%", height: "300px", alignItems: "center", justifyContent: "center", backgroundColor: "gainsboro" }}>Drop the image here</div> :
        <img width="100%" height="auto" src={section.image.uri} />
      }
    </div >
  )
}

const Columns = ({ index, updateCallback, columnsData, edit }) => {

  const [columns, setColumns] = useState(columnsData)
  const colSize = 12 / columnsData.length

  useEffect(() => {
    setColumns(columnsData)
  }, [columnsData])

  useEffect(() => {
    edit && updateCallback([...columns])
  }, [edit])

  const editColumn = (i, field, data) => {
    // console.log(i, field, data)
    const arr = columnsData
    arr[i][field] = data

    setColumns([...arr])
    updateCallback([...arr])
  }

  const removeColumn = (i) => {
    setColumns(columns.filter((el, index) => index != i))
    updateCallback(columns.filter((el, index) => index != i))
  }

  return (
    <div key={index} type="bColumns" className={`bColumns columnContainer center row mv`}>
      {columns.map((col, i) => {
        return (
          <div key={i} style={{ position: "relative" }} type="bCol" className={`bCol thirdColumn col-md-${colSize}`}>
            {edit && <Button primary style={{ position: "absolute", top: 0, left: 0 }} onClick={() => removeColumn(i)} size="small" color="status-critical" icon={<Icon.Trash size="small" color="white" />} />}
            <ImageElement edit={edit} dropCallback={(ev) => editColumn(i, "image", ev)} edit={edit} section={col} />
            {edit ?
              col.text && <ReactQuill key={2} theme="snow" value={col.text} onChange={event => editColumn(i, "text", event)} />
              :
              <div style={{ marginTop: 25 }} dangerouslySetInnerHTML={{ __html: col.text }} />
            }
          </div>
        )
      })}
    </div>
  )
}

const TextBlock = ({ index, updateCallback, section, edit }) => {
  return (
    <div key={index} type="bTextBlock" className="bTextBlock singleColumn mv row">
      <div type="bTextBlock" className="innerText col-md-12" style={{ width: "100%" }}>
        {edit ? [
          section.text && <ReactQuill key={2} theme="snow" value={section.text} onChange={event => updateCallback(event)} />]
          :
          <div dangerouslySetInnerHTML={{ __html: section.text }} />
        }
      </div>
    </div>
  )
}

const TitleBlock = ({ index, updateCallback, section, edit, level }) => {
  return (
    <div key={index} className="bTitleBlock singleColumn mv row">
      <div type="bTitleBlock" className="innerText col-md-12" style={{ width: "100%" }}>
        {edit ?
          <TextareaAutosize
            onChange={event => updateCallback(event.target.value)}
            value={section.text}
            style={{ fontSize: 24, fontWeight: 700, width: "100%", height: "auto", fontFamily: "Montserrat" }}
          />
          :
          <h3 style={{ margin: 0, fontFamily: "Montserrat", fontSize: "24px", lineHeight: "28px" }}>{section.text}</h3>
        }
      </div>
    </div>
  )
}

const ImageBlock = ({ index, updateCallback, section, edit }) => {

  return (
    <div key={index} className="bImageBlock center mv row" >
      <div type="bImageBlock" filename={section.image.filename} background={section.image.uri} className={`col-md-12`} >
        <ImageElement section={section} edit={edit} dropCallback={(ev) => updateCallback(ev)} className="imageCol" />
      </div>
    </div>
  )
}

// style={{ backgroundPosition: "center", backgroundSize: "cover", backgroundImage: `url(${section.image.uri})` }}

const EditBlock = ({ type, reorderCallback, deleteCallback, i, length, addColumnCallback }) => {
  return <Box direction="row" gap="5px" style={{ position: "absolute", top: 10, left: 10 }}>

    {type === 2 && <Button size="small" style={{ borderRadius: 5 }} color="black" onClick={() => addColumnCallback(i)} primary icon={<Icon.Split size="small" />} />}
    <Button size="small" style={{ borderRadius: 5 }} color="black" disabled={i === 0} onClick={() => reorderCallback(i, i - 1)} primary icon={<Icon.Ascend size="small" />} />
    <Button size="small" style={{ borderRadius: 5 }} color="black" disabled={i + 1 >= length} onClick={() => reorderCallback(i, i + 1)} primary icon={<Icon.Descend size="small" />} />
    <Button size="small" style={{ borderRadius: 5 }} onClick={() => deleteCallback(i)} primary color="status-critical" icon={<Icon.Trash size="small" color="white" />} />
  </Box>
}

function App() {

  const [sections, setSections] = useState([])
  const [editMode, setEditMode] = useState(true)
  const [products, setProducts] = useState([])
  const [newDescription, setNewDescription] = useState("")
  const [productSelected, setProductSelected] = useState(null)
  const [shortDescription, setShortDescription] = useState("")
  const [loading, setLoading] = useState(true)
  const [username, setUsername] = useState("black")
  const [password, setPassword] = useState("sanabul")
  const [loggedIn, setLoggedIn] = useState(false)


  useEffect(() => {
    loggedIn && fetchData()
  }, [loggedIn])


  const exportHTML = async (data) => {
    setLoading(true)
    Promise.all(data.map(async (section) => {
      if (section.type === 3) {
        const img = section.image.uri.includes("http") ? section.image.uri : await uploadImage(section.image.uri, section.image.filename)
        return {
          ...section,
          image: {
            ...section.image,
            uri: section.image.uri.includes("http") ? section.image.uri : img.src
          }
        }
      } else if (section.type === 2) {
        const cols = await Promise.all(section.columns.map(async (col) => {
          const img = col.image.uri.includes("http") ? col.image.uri : await uploadImage(col.image.uri, col.image.filename)
          return {
            ...col,
            image: {
              ...col.image,
              uri: col.image.uri.includes("http") ? col.image.uri : img.src
            }
          }
        }))
        return {
          ...section,
          columns: cols
        }
      } else {
        return section
      }
    })).then(async res => {
      // console.log(res)
      const ex = res.map((section, i) => {
        const string = section.type === 1 ?
          renderToString(<TextBlock key={i} edit={false} index={i} section={section} />)
          :
          section.type === 2 ?
            renderToString(<Columns key={i} columnsData={section.columns} index={i} edit={false} />)
            :
            section.type === 3 ?
              renderToString(<ImageBlock key={i} index={i} section={section} edit={false} />)
              : section.type === 4 ?
                renderToString(<TitleBlock key={i} edit={false} index={i} section={section} />)
                : ""
        return string.replace('data-reactroot=""', "")
      })
      await updateDescription("[short_description]" + shortDescription + "[/short_description]|[config]|" + ex.join("")).then(res => {
        // console.log(res)
        setLoading(false)
      })
    })
  }

  const fetchData = async () => {
    setLoading(true)
    const headers = new Headers()
    fetch('https://us-central1-shopifyproductbuilder.cloudfunctions.net/list', {
      method: 'GET'
    }
    )
      .then(response => response.json())
      .then(data => {
        setProducts(data)
        setLoading(false)
      })
      .catch(console.error);
  }

  const parse = (data) => {
    const el = new DOMParser().parseFromString(data, "text/html")
    const rows = el.getElementsByClassName("row")

    // console.log(el)

    setSections(Array.from(rows).map((el, i) => {

      const type = rows[i].childNodes[0].nodeName !== "#text" ? rows[i].childNodes[0].getAttribute("type") : "bgCol"

      // console.log("TIPO DE COSAS: ", rows[i].childNodes[0])
      // console.log(type, rows[i].querySelectorAll(".thirdColumn"))

      if (type === "bImageBlock") {
        const uri = rows[i].childNodes[0].getAttribute("background")
        const filename = rows[i].childNodes[0].getAttribute("filename")
        return {
          type: 3,
          image: {
            uri,
            filename
          }
        }
      } else if (type === "bTitleBlock") {
        return {
          type: 4,
          text: rows[i].childNodes[0].innerHTML
        }
      } else if (type === "bTextBlock") {
        // console.log("TEEEXXXTO")
        // console.log(rows[i].childNodes[0].innerHTML)
        // console.log(rows[i].childNodes[0])
        // console.log(rows[i].childNodes)
        return {
          type: 1,
          text: rows[i].childNodes[0].innerHTML
        }
      } else {
        const colIndex = i
        const cols = rows[i].querySelectorAll(".thirdColumn")
        const columns = Array.from(cols).map((el, i) => {
          // console.log(cols[i].children[1].innerHTML)
          return ({
            image: {
              uri: cols[i].children[0].firstElementChild.src,
              filename: ""
            },
            text: cols[i].children[1].innerHTML
          })
        })
        // console.log(cols)
        // console.log(columns)
        return {
          type: 2,
          text: "",
          columns
        }
      }
    }
    ))
  }

  const uploadImage = async (attachment, filename) => {
    return fetch(`https://us-central1-shopifyproductbuilder.cloudfunctions.net/uploadImage`, {
      method: 'POST',
      body: JSON.stringify({
        id: productSelected.id,
        image: {
          attachment,
          filename
        }
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }
    )
      .then(response => response.json())
      .then(data => {
        console.log(data)
        return data
      })
      .catch(console.error);
  }

  const updateDescription = async (description) => {
    await fetch(`https://us-central1-shopifyproductbuilder.cloudfunctions.net/update`, {
      method: 'PUT',
      body: JSON.stringify({
        id: productSelected.id,
        body_html: description
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }
    )
      .then(response => response.json())
      .then(data => {
        // console.log("SUBIDO")
        // console.log(data)
        return data
      }).catch(err => {
        console.log(err)
        return err
      });
  }

  const addSection = (type, content) => {
    setSections([
      ...sections,
      {
        type,
        image: {
          uri: "https://nutrisport.es/web/wp-content/uploads/2019/12/placeholder.png",
          filename: "placeholder.png"
        },
        text: content,
        marginTop: 25,
        marginBottom: 25,
        columns: [{
          image: {
            uri: "https://nutrisport.es/web/wp-content/uploads/2019/12/placeholder.png",
            filename: "placeholder.png"
          },
          text: "Lorem Ipsum"
        },
        {
          image: {
            uri: "https://nutrisport.es/web/wp-content/uploads/2019/12/placeholder.png",
            filename: "placeholder.png"
          },
          text: "Lorem Ipsum"
        },
        {
          image: {
            uri: "https://nutrisport.es/web/wp-content/uploads/2019/12/placeholder.png",
            filename: "placeholder.png"
          },
          text: "Lorem Ipsum"
        }]
      }
    ])
  }

  const editElement = (i, type, ev) => {
    const arr = sections
    arr[i][type] = ev
    setSections([...arr])
  }

  const reorderElements = (from, to) => {
    const arr = sections
    const el = arr[from]
    arr[from] = arr[to]
    arr[to] = el
    setSections([...arr])
  }

  const deleteElements = (index) => {
    setSections(sections.filter((el, i) => i != index))
  }

  const addColumn = (index) => {

    const arr = sections
    arr[index].columns = [
      ...arr[index].columns,
      {
        image: {
          uri: "https://nutrisport.es/web/wp-content/uploads/2019/12/placeholder.png",
          filename: "placeholder.png"
        },
        text: "Lorem Ipsum"
      }
    ]

    setSections([...arr])
  }

  const theme = {
    global: {
      font: {
        family: 'Montserrat',
        size: '16px',
        height: '20px',
      }
    },
  };

  if (!loggedIn) {
    return (
      <Grommet theme={theme} full>
        <Box align="center" justify="center" fill={true} background="black">
          <Box background="white" pad="medium" round="small" width="medium" gap="small">
            <TextInput onChange={(ev) => setUsername(ev.target.value)} value={username} placeholder="User" />
            <TextInput type="password" onChange={(ev) => setPassword(ev.target.value)} value={password} placeholder="Password" />
            <Button primary color="black" label="Login" onClick={() => username === "black" && password === "sanabul" && setLoggedIn(true)} />
          </Box>
        </Box>
      </Grommet>
    )
  }
  return (
    <Grommet theme={theme}>
      {loading && (
        <Layer>
          <Box pad="medium">
            <Image width="250px" src="https://media0.giphy.com/media/DqZMa7D9nK5sEdmfPy/giphy.gif" />
          </Box>
        </Layer>
      )}
      <div className="App" style={{ flex: 1, justifyContent: "center" }}>
        {/* <Text>{products.map((el)=> el.title).join(",")}</Text> */}
        <Box style={{ position: "fixed", top: "0px", zIndex: 10 }} width="100%" direction="row" gap="small" pad={{ horizontal: "medium" }} align="center" justify="between" background="black">
          <Box pad={{ vertical: "small" }}>
            <Image src={"https://m.media-amazon.com/images/S/abs-image-upload-na/3/AmazonStores/ATVPDKIKX0DER/0344596ccfd452b960283f4dfa4cab67.w1000.h1000.jpg"} height="80px" />
          </Box>
          {productSelected && <Box direction="row" gap="small">
            <Button primary icon={<Icon.TextAlignFull />} onClick={() => addSection(1, textToSend)} />
            <Button primary icon={<Icon.Bold />} onClick={() => addSection(4, "Title Goes Here")} />
            <Button primary icon={<Icon.Image />} onClick={() => addSection(3, textToSend)} />
            <Button primary icon={<Icon.Splits />} onClick={() => addSection(2, textToSend)} />
          </Box>}
          <Box direction="row" gap="small">
            <Button primary color={!editMode ? "status-critical" : "black"} label={editMode ? "Preview" : "Edit"} onClick={() => setEditMode(!editMode)} />
            <Button primary color="white" icon={<Icon.Share />} label="Upload" onClick={() => exportHTML(sections)} />
          </Box>
        </Box>

        <Box margin={{ top: "100px" }} pad="medium" gap="small" background="light-2">
          <Select
            style={{ backgroundColor: "white" }}
            value={productSelected}
            placeholder="Select a Product"
            labelKey="title"
            onChange={({ option }) => {
              setProductSelected(option)
              setShortDescription(option.body_html.split('[short_description]').pop().split('[/short_description]|[config]|')[0])
              setNewDescription(option.body_html)
              parse(option.body_html)
            }}
            options={products}
          />
          {productSelected && <Box gap="small">
            <Text size="small">Short Description</Text>
            <ReactQuill theme="snow" value={shortDescription} onChange={setShortDescription} />
          </Box>}
        </Box>
        <Box width="100%" align="center">
          <Box width={{ max: "1024px" }} style={{ width: "100%"}}>
            {sections.map((section, i) => {
              if (section.type === 1) {
                return (
                  <div key={i} style={{ position: "relative" }}>
                    <TextBlock key={i} edit={editMode} index={i} section={section} updateCallback={(ev) => editElement(i, "text", ev)} />
                    {editMode && <EditBlock type={section.type} i={i} length={sections.length} reorderCallback={(from, to) => reorderElements(from, to)} deleteCallback={(el) => deleteElements(el)} />}
                  </div>
                )
              } else if (section.type === 2) {
                return (
                  <div key={i} style={{ position: "relative" }}>
                    <Columns key={i} updateCallback={(ev) => editElement(i, "columns", ev)} columnsData={section.columns} index={i} edit={editMode} />

                    {editMode && <EditBlock type={section.type} i={i} addColumnCallback={() => addColumn(i)} length={sections.length} reorderCallback={(from, to) => reorderElements(from, to)} deleteCallback={(el) => deleteElements(el)} />}
                  </div>
                )
              } else if (section.type === 3) {
                return (
                  <div key={i} style={{ position: "relative" }}>
                    <ImageBlock key={i} index={i} updateCallback={(ev) => editElement(i, "image", ev)} section={section} edit={editMode} />

                    {editMode && <EditBlock type={section.type} i={i} length={sections.length} reorderCallback={(from, to) => reorderElements(from, to)} deleteCallback={(el) => deleteElements(el)} />}
                  </div>
                )
              } else if (section.type === 4) {
                return (
                  <div key={i} style={{ position: "relative" }}>

                    <TitleBlock key={i} edit={editMode} index={i} section={section} updateCallback={(ev) => editElement(i, "text", ev)} />
                    {editMode && <EditBlock type={section.type} i={i} length={sections.length} reorderCallback={(from, to) => reorderElements(from, to)} deleteCallback={(el) => deleteElements(el)} />}
                  </div>
                )
              }
            })}
          </Box>
        </Box>

        {productSelected && <Box background="light-2" pad="medium" gap="small">
          <Text>Product RAW Description</Text>
          <TextArea
            style={{ backgroundColor: "white" }}
            onChange={event => setNewDescription(event.target.value)}
            value={newDescription}
          />
        </Box>}

      </div>
    </Grommet>
  );
}

export default App;